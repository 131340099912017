.block-container{
    width:90%;
    margin-left:5%;
    border: solid 2px grey;
    margin-bottom:2%;
    text-align:left;
    padding:5px;
}


.question {
    font-family:"Poppins";
    color:white;
    text-shadow: 1px 1px grey;
    font-size:1.5em;
    font-weight:600;

}

.answer {
    margin-top:1%;
    margin-bottom:1%;
    color:white;
}