.main-container-inner {
    padding-top: 100px;
    width: 80%;
    margin-left: 10%;
}

.contact-title {
    color: rgb(200, 200, 200);
    font-size: 2em;
    padding-bottom: 50px;
}


.contact-body {
    color: rgb(200, 200, 200);
    font-size: 1em;
}


.background-static {
    position: fixed;
    background-image: url("media/Tess-wob-logo.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-color: rgb(20, 20, 20);
    background-blend-mode: soft-light;
    width: 100%;
    height: 100vh;
    z-index: -1;
}


@media screen and (max-width:800px) {
    .background-static {
        position:fixed;
        background-image:url("media/Tess-wob-logo.png");
        background-size:cover;
        background-repeat: no-repeat;
        background-position:50% 0%;
        background-color:rgb(20,20,20);
        background-blend-mode: soft-light;
        width:100%;
        height:100vh;
        z-index: -1;
    }
}