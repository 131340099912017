@import url('https://fonts.googleapis.com/css2?family=Poppins');


.background-static {
    position:fixed;
    background-image:url("media/Tess-wob-logo.png");
    background-size:cover;
    background-repeat: no-repeat;
    background-color:rgb(20,20,20);
    background-blend-mode: soft-light;
    width:100%;
    height:100vh;
    z-index: -1;
}

.main-container-inner{
    padding-top:100px;
    width:80%;
    margin-left:10%;
}

.brand-section {
    padding-bottom:10%;
}
.brand-section-title {
    color:rgb(0, 238, 255);
    font-family:"Poppins";
    text-align:left;
    padding-bottom:20px;
}

.brand-section-body {
    color:rgb(200,200,200);
    font-family:"Poppins";
    text-align:left;
}

.team-info-container {
    width:100%;
    text-align:center;
    display:flex;
    flex-direction: column;    
    align-items: center;
    justify-items: center;
    padding-top:100px;
    padding-bottom:100px;
}

.team-section-banner{
    min-width:0;
    width:50%;
    height:auto;
    align-self:center;
    flex:1;
}

.team-info-title{
    font-family:"Poppins";
    color:rgb(200,200,200);
    width:60%;
}

.team-profile-pic {
    border:solid 2px grey;
    border-radius:120px;
    min-width:0;
    height:200px;
}

.team-info-body {
    text-align:left;
}

@media screen and (max-width:800px) {

    .background-static {
        position:fixed;
        background-image:url("media/Tess-wob-logo.png");
        background-size:cover;
        background-repeat: no-repeat;
        background-position:50% 0%;
        background-color:rgb(20,20,20);
        background-blend-mode: soft-light;
        width:100%;
        height:100vh;
        z-index: -1;
    }

    .team-info-title{
        width:100%;
    }
}