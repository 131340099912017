.main-container {

}

.signup-button {
    display:table-cell;
    background:  linear-gradient(0deg, rgba(89,54,0,1) 0%, rgba(47,32,0,1) 47%, rgba(74,74,74,1) 100%);
    text-decoration:none;
    border:solid 1px white;
    border-radius: 6px;
    vertical-align: middle;
    display:inline;
    width:auto;
    padding-left:2%;
    padding-right:2%;
    height:35px;
    line-height:35px;
}

.signup-button a {
    text-decoration:none;
    color:white;
}

.background-static {
    position:fixed;

    /*
    background-image: url("media/Tess-wob-logo.png");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: 50%;
    background-color:rgb(20,20,20); 
    background-blend-mode:soft-light;
    height:100vh;
    width:100%;
    z-index: -1;
    */
}


.signup-container {
    padding-top:5%;
}


@media only screen and (max-width:800px) {
    .background-static {
        /*
        position:fixed;    
        background-image: url("media/Tess-wob-logo.png");
        background-size:cover;
        background-color:rgb(20,20,20); 
        background-blend-mode:soft-light;
        height:100vh;
        width:100%;
        z-index: -1;
        */
    }
}