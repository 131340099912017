.main-container {
    width:100%;
}
.main-inner {
    padding-top:100px;
}

.static-background {
    padding-top:-100px;
    position:fixed;
    width:100%;
    height:100vh;
    background-size: cover;
    z-index: -1;
    opacity:0.2;
}

.main-title {
    font-family:"poppins";
    width:100%;
    font-weight:600;
    text-align:center;
    color:white;
    font-size:3em;
    text-shadow: 2px 2px black;
}

.section-1-body {
    font-family:"poppins";
    width:90%;
    margin-left:5%;
}

.section-1-row {
    width:95%;
    display:flex;
    flex-direction:row;
    flex-wrap:wrap-reverse;
    border:solid 1px white;
    padding:10px;
    border-radius:8px;
    background-color:rgba(40,40,40,0.8);
}


.section-1-left {
    text-align:left;
    flex:1;
    flex-grow:1;
    color:white;
}

.section-1-left h2 {
    text-shadow: 2px 2px black;
}

.main-container a {
    text-decoration: none;
    color:rgb(145, 60, 145);
    font-weight:600;
}

.section-1-right {
    background-size: contain;
    background-repeat:no-repeat;
    background-position: center;
    min-width:200px;
    flex:1;
    justify-self:center;
    align-self:center;
    flex-grow:1;
    text-align:left;
    color:white;
}

@media only screen and (max-width:800px) {
    .section-1-left{
        width:100%;
    }

    .section-1-right {
        min-width:100%;
        max-width:100%;
        align-self:center;
    }
}