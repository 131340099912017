@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;1,100&display=swap');
@import url('https://fonts.cdnfonts.com/css/playfair-display');

.home-container {
    height:100vh;
    color:white;    
    font-family: 'Poppins', sans-serif;    
}

.news-header-container {
    display:flex;
    position:fixed;
    width:100%;
    justify-content:center;
    text-align:center;
    top:60px;
    z-index:-1;
}

.news-header {
    transform:skew(-20deg);
    font-size:1.5em;
    padding:1%;
    width:auto;
    background-color:rgba(8, 8, 22, 0.6);
    border:solid 2px rgb(204, 204, 248);
    text-shadow: 2px 2px black;
    cursor:pointer;    
}

.news-header * {
    text-decoration: none;
    color:white;
}

.border-rainbow {
    --angle: 0deg;    
    border-image: conic-gradient(from var(--angle), red, yellow, lime, aqua, blue, magenta, red) 1;    
    animation: 5s rotate linear infinite;

}



.border-greyblue-gradient-animated {
    --angle: 0deg;
    width: 50vmin;
    border-image: conic-gradient(from var(--angle), black, blue, purple, black) 1;

    animation: 4s rotate linear infinite;
}

@keyframes rotate {
    to {
        --angle: 360deg;
    }
}

@property --angle {
    syntax: '<angle>';
    initial-value: 0deg;
    inherits: false;
}


.section-one-container {
    position:fixed;
    width:100%;
    z-index: -1;
}

.section-one-inner {
    display:flex;
    flex-direction: column;
    padding-top:5%;
    width:80%;
    margin-left:10%;
    align-content: center;
}

.section-one-overlay-container {        
    display:flex;
    flex-direction: column;
    height:100vh;
    width:100%;
    justify-content: space-between;
}

.section-one-overlay-inner {
    display:flex;
    flex-direction: row;
    padding-top:10%;
    min-width:0;
    width:90%;
    margin-left:10%;
    justify-content: space-between;
}

.section-one-indevelopment {
    display:flex;
    flex-direction: column;
    min-width:0;
    width:50%;
    justify-content: left;
}

.blog-outer {
    display:flex;    
    justify-items: flex-start;
    align-items: flex-start;
    flex:1;
    text-align:right;
    margin-right:10%;
    width:40%;
}

.blog-container {
    width:80%;
    border-radius:8px;
    flex:1;
    font-family: "Poppins";
    color:rgb(180,180,180);
    padding-bottom:5%;
    max-height:250px;
}

.blog-container-inner{
    height:100px;
}
.nav-image {
    flex:1;
    max-height:15vmin;
}

.blog-title-container {
    width:100%;
}

.blog-container h1 {
    font-size:2.5vmin;
    font-weight:400;
    padding:0;
    padding-top:2%;
    padding-bottom:2%;
    margin:0;
}

.blog-body {
    padding-top:5%;
    text-align:left;
    margin-left:5%;
    font-size:1.5vmin;
}

.steam-link-container {
    align-self:left;
    display:flex;
    flex-direction:row;
    width:60%;
    justify-content:space-between;
}

.steam-link-image {
    min-width:0;
    width:120px;
    padding-left:0px;
    margin-right:2%;
}

.section-one-title {
    color:white;
    font-family: "Poppins";
    font-weight:200;
    text-align:left;
    padding-left:0%;
    font-size:1em;
}

.section-one-banner-container {
    width: 60%;
    height: auto;
    display:flex;
    justify-content: left;
    min-width: 0;
    text-align:left;
}

.section-one-banner {
    flex-shrink:1;
    width:100%;
    height:auto;
}


.section-two-container {
    border-top: solid 1px rgb(100,100,100);
    border-bottom:solid 1px rgb(100,100,100);
    background:rgba(0,0,0,0.5);
}


.section-two-inner {
    display:flex;
    flex:1;
    flex-direction: column;
    padding-top:2.5%;
    padding-bottom:2.5%;
    width:80%;
    margin-left:10%;
}

.section-two-title {
    font-family: "Poppins";
    font-weight:600;
    color:rgb(180,180,180);
    text-transform: uppercase;
    text-align:left;
    font-size:1em;
}

.section-two-body {
    margin-top:1%;
    font-size:1em;
    font-weight:200;
    text-align:left;
}

.kickstarter-link {
    text-decoration:none;
    color:rgb(180,180,180);
    text-decoration: underline;
    font-weight:500;
}

.tesseract-link {
    text-decoration:none;
    color:white;
    font-weight:400;
}

.video-settings {
    height:100%;
    width:100%;
}

.section-three {
    border-top:solid 1px rgb(80,80,80);
    background-color:rgb(0,0,0);
}

.section-three-inner{
    display:flex;
    flex:1;
    flex-direction: column;
    padding-top:2.5%;
    padding-bottom:2.5%;
    width:80%;
    margin-left:10%;
    text-align:left;
}

@media screen and (max-width: 800px) {

    .news-header {
        font-size:1em;
    }
    .home-container {
        height:100vh;
    }

    .nav-image {
        height:50px;
    }

    .section-one-overlay-container {
        padding-top:100px;
        height: 90%;
        width:100%;
        margin-left:0;
    }

    .section-one-overlay-inner {
        width:100%;
        justify-content: center;       
        margin-left:0; 
        flex-wrap:wrap;
    }

    .section-two-container {
        bottom:0;
    }

    .section-two-body {
        font-size:11px;
    }

    .section-one-title {
        text-align:center;
    }

    .section-one-banner-container {
        width:100%;
        justify-content:center;
        width:auto;
    }

    
    .section-one-banner {
        width:80%;
        height:auto;
    }

    .steam-link-container {
        width:75%;
        text-align:left;
        margin-left:15%;
    }
    
    .steam-link-image {
        min-width:0;
        width:90px;
    }


    .video-settings {
        height:100vh;
        width:auto;
    }
}