.main-container {
    width:100%;
    background-color:rgb(0, 46, 65);    
    height:100vh;
}


.registration-container-outer {
    display:flex;
    flex-direction: row;
    width:100%;
    padding-top:100px;
    justify-content: center;
}

.registration-container {
    display:flex;
    flex-direction: column;
    align-items:flex-start;
    min-width:33%;
    max-width:80%;
    border:solid 1px red;
    height:400px;
    font-weight:400;
    color:white;
}


@media only screen and (max-width: 800px) {

    .registration-container {
        min-width:80%;
        max-width:90%;
    }
}