@import url('https://fonts.googleapis.com/css?family=Poppins');

.navigation-container {
    position: fixed;
    display: flex;
    width: 100%;
    background-color:rgba(20,20,20,0.5);
    height:50px;
    justify-content: space-between;
    border-bottom:solid 1px white;

}

.navigation-links-container {
    display:flex;
    flex-direction: row;
    gap: 5%;
    margin-left:5%;
    height:auto;
    flex:1;
    font-family:"Poppins";
    font-weight:300;
    text-transform: uppercase;
    color:white;
}

.brand-logo {
    background-image: url("media/DMT-logotype-white.png");
    background-repeat:no-repeat;
    background-size: 213px 23px;
    background-position: 0 15px;
    height:50px;
    width:213px;
    margin-left:1%;
}

.navigation-link-item {
    display: inline-block;
    list-style-type: none;
    cursor:pointer;
    gap: 5px;
}

.link-dropdown, .link-dropdown:visited, .link-dropdown:active {
    text-decoration: none;
    color:white;
    font-weight: 200;
}

.navigation-dropdown {
    display:block;
    background-color: rgba(50,50,50,1);    
}

.hidden-menu {
    display:none;
}


.navigation-burger-menu {
    display: none;
}

.burger-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
    display: none;
    transition: 0.5s;
}



@media only screen and (max-width: 800px) {

    .navigation-links-container {
        display:none;
        width:0;
    }
    .navigation-link-item {
        display:none;
    }

    .navigation-container {
        justify-content:space-between;
    }
    .navigation-burger-menu {
        display:block;
        flex-direction:column;
        justify-self: right;
        margin-right:2%;
        align-self:center;
   
    }

    .burger-menu-line {
        width: 30px;
        height: 3px;
        background-color: white;
        border-radius:10px;
        margin: 6px 0;
        transition: 0.4s;
    }

    .burger-menu-category-title {
        width:100%;
        color:rgb(50,50,50);
        font-size:0.8em;
        text-align:left;
        border-bottom: solid 1px rgb(50,50,50);
        padding:5px;
    }

    .burger-menu-link {
        width:100%;
        color:white;
        text-decoration:none;
        
        z-index: 100;
    }

    .burger-menu-link-item{
        width:90%;
        text-align:left;
        padding:5px;
        margin-top:10px;
        padding-left:15px;
        padding-bottom:10px;
        border-bottom:solid 1px white;
    }
}